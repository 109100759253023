import React from "react";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  paper: {
    maxWidth: 936,
    margin: "auto",
    overflow: "hidden"
  },
  searchBar: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)"
  },
  searchInput: {
    fontSize: theme.typography.fontSize
  },
  block: {
    display: "block"
  },
  addUser: {
    marginRight: theme.spacing.unit
  },
  contentWrapper: {
    margin: "40px 16px"
  }
});

function Welcome() {
  return (
    <div style={{ margin: "20px", padding: "20px", backgroundColor: "white" }}>
      <h2>¡Bienvenido!</h2>
      <p style={{ fontSize: "1rem" }}>
        Despliega el menú de la izquierda para ver las opciones disponibles.
        Estamos trabajando para agregar nuevos clubes gallísticos.
      </p>
      <p style={{ fontSize: "1rem" }}>
        Si necesita ayuda o tiene preguntas, contáctenos por
        <strong>
          {" "}
          WhatsApp al <a href="https://wa.me/18292645257">829-264-5257</a>
        </strong>
        .
      </p>
    </div>
  );
}

export default withStyles(styles)(Welcome);
