import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";

class PayPalButton extends React.Component {
  static propTypes = {
    amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    currency: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onSuccess: PropTypes.func,
    catchError: PropTypes.func,
    onError: PropTypes.func,
    createOrder: PropTypes.func,
    createSubscription: PropTypes.func,
    onApprove: PropTypes.func,
    style: PropTypes.object,
    options: PropTypes.shape({
      clientId: PropTypes.string,
      merchantId: PropTypes.string,
      currency: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      intent: PropTypes.string,
      commit: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
      vault: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
      component: PropTypes.string,
      disableFunding: PropTypes.string,
      disableCard: PropTypes.string,
      integrationDate: PropTypes.string,
      locale: PropTypes.string,
      buyerCountry: PropTypes.string,
      debug: PropTypes.oneOfType([PropTypes.bool, PropTypes.string])
    }),
    onButtonReady: PropTypes.func
  };

  static defaultProps = {
    style: {},
    options: {
      clientId: "sb",
      currency: "USD"
    }
  };

  constructor(props) {
    super(props);

    this.state = {
      isSdkReady: false
    };
  }

  componentDidMount() {
    if (
      typeof window !== "undefined" &&
      window !== undefined &&
      window.paypal === undefined
    ) {
      this.addPaypalSdk();
    } else if (
      typeof window !== "undefined" &&
      window !== undefined &&
      window.paypal !== undefined &&
      this.props.onButtonReady
    ) {
      this.props.onButtonReady();
    }
  }

  createOrder(datay, actions) {
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            currency_code: this.props.currency
              ? this.props.currency
              : this.props.options && this.props.options.currency
              ? this.props.options.currency
              : "USD",
            value: this.props.amount.toString()
          }
        }
      ]
    });
  }

  onApprove(data, actions) {
    return actions.order
      .capture()
      .then(details => {
        if (this.props.onSuccess) {
          return this.props.onSuccess(details, data);
        }
      })
      .catch(err => {
        if (this.props.catchError) {
          return this.props.catchError(err);
        }
      });
  }

  render() {
    const { style } = this.props;
    const { isSdkReady } = this.state;

    if (
      !isSdkReady &&
      (typeof window === "undefined" || window.paypal === undefined)
    ) {
      return null;
    }

    const Button = window.paypal.Buttons.driver("react", {
      React,
      ReactDOM
    });

    return <Button {...this.props} style={style} />;
  }

  addPaypalSdk() {
    const { options, onButtonReady } = this.props;
    const queryParams = [];

    // replacing camelCase with dashes
    Object.keys(options).forEach(k => {
      const name = k
        .split(/(?=[A-Z])/)
        .join("-")
        .toLowerCase();
      queryParams.push(`${name}=${options[k]}`);
    });

    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = `https://www.paypal.com/sdk/js?${queryParams.join("&")}`;
    script.async = true;
    script.onload = () => {
      this.setState({ isSdkReady: true });

      if (onButtonReady) {
        onButtonReady();
      }
    };
    script.onerror = () => {
      throw new Error("Paypal SDK could not be loaded.");
    };

    document.body.appendChild(script);
  }
}

export { PayPalButton };
