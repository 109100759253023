import firebase from "firebase/app";
import "firebase/auth";

const config = {
  apiKey: "AIzaSyDmj2lDDsB9rgVA7Uwaj8D4g8uITO7uxl8",
  authDomain: "gallerismo-com.firebaseapp.com",
  databaseURL: "https://gallerismo-com.firebaseio.com",
  projectId: "gallerismo-com",
  storageBucket: "gallerismo-com.appspot.com",
  messagingSenderId: "1069748142501"
};

firebase.initializeApp(config);

export default firebase;
