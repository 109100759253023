import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import PeopleIcon from "@material-ui/icons/People";
import PersonIcon from "@material-ui/icons/Person";
import LiveVideoIcon from "@material-ui/icons/OndemandVideo";
import CardMembership from "@material-ui/icons/CardMembership";
import SettingsIcon from "@material-ui/icons/Settings";
import ExitIcon from "@material-ui/icons/ExitToApp";

import firebase from "../../firebase";
import siteIcon from "assets/img/icon.png";

const styles = theme => ({
  categoryHeader: {
    paddingTop: 16,
    paddingBottom: 16
  },
  categoryHeaderPrimary: {
    color: theme.palette.common.white
  },
  item: {
    paddingTop: 4,
    paddingBottom: 4,
    color: "rgba(255, 255, 255, 0.7)"
  },
  itemCategory: {
    backgroundColor: "#3e2323",
    boxShadow: "0 -1px 0 #544040 inset",
    paddingTop: 16,
    paddingBottom: 16
  },
  firebase: {
    fontSize: 24,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.common.white
  },
  itemActionable: {
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.08)"
    }
  },
  itemActiveItem: {
    color: "#f75050"
  },
  itemPrimary: {
    color: "inherit",
    fontSize: theme.typography.fontSize,
    "&$textDense": {
      fontSize: theme.typography.fontSize
    }
  },
  textDense: {},
  divider: {
    marginTop: theme.spacing.unit * 2
  }
});

function Navigator(props) {
  const { classes, history, onChannelSelect, ...other } = props;
  const [activeItem, setActiveItem] = useState("");

  const categories = [
    {
      id: "Transmisión en vivo",
      children: [
        {
          id: "lomadecabrera",
          text: "Loma de Cabrera",
          icon: <LiveVideoIcon />,
          onClick: onChannelSelect
        },
        {
          id: "mao",
          text: "Coliseo de Mao",
          icon: <LiveVideoIcon />,
          onClick: onChannelSelect
        },
        {
          id: "losbombones",
          text: "Club Los Bombones",
          icon: <LiveVideoIcon />,
          onClick: onChannelSelect
        },
        {
          id: "lagunaprieta",
          text: "Laguna Prieta",
          icon: <LiveVideoIcon />,
          onClick: onChannelSelect
        },
        {
          id: "corozito",
          text: "Club Corozito",
          icon: <LiveVideoIcon />,
          onClick: onChannelSelect
        },
        {
          id: "hermanospena",
          text: "Coliseo Hermanos Peña",
          icon: <LiveVideoIcon />,
          onClick: onChannelSelect
        }
      ]
    },
    {
      id: "Menú Secundario",
      children: [
        /*
        { id: "membresia", text: "Membresía", icon: <CardMembership /> },
        { id: "perfil", text: "Perfil", icon: <PersonIcon /> },
        { id: "configuracion", text: "Configuración", icon: <SettingsIcon /> }
        */
        {
          id: "cerrarSesion",
          text: "Cerrar sesión",
          icon: <ExitIcon />,
          onClick: () => {
            firebase
              .auth()
              .signOut()
              .then(history.push("/login"));
          }
        }
      ]
    }
  ];

  const handleItemClick = (id, onClick) => {
    return () => {
      setActiveItem(id);
      if (typeof onClick === "function") onClick(id);
    };
  };

  return (
    <Drawer variant="permanent" {...other}>
      <List disablePadding>
        <ListItem
          className={classNames(
            classes.firebase,
            classes.item,
            classes.itemCategory
          )}
        >
          <img
            alt=""
            height="48px"
            width="48px"
            style={{ marginRight: "15px" }}
            src={siteIcon}
          />
          Gallerismo
        </ListItem>
        <ListItem className={classNames(classes.item, classes.itemCategory)}>
          <ListItemIcon>
            <PeopleIcon />
          </ListItemIcon>
          <ListItemText
            classes={{
              primary: classes.itemPrimary
            }}
          >
            Panel de Usuario
          </ListItemText>
        </ListItem>
        {categories.map(({ id, children }) => (
          <React.Fragment key={id}>
            <ListItem className={classes.categoryHeader}>
              <ListItemText
                classes={{
                  primary: classes.categoryHeaderPrimary
                }}
              >
                {id}
              </ListItemText>
            </ListItem>
            {children.map(({ id: itemId, text, icon, onClick }) => (
              <ListItem
                onClick={handleItemClick(itemId, onClick)}
                button
                dense
                key={text}
                className={classNames(
                  classes.item,
                  classes.itemActionable,
                  itemId === activeItem && classes.itemActiveItem
                )}
              >
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText
                  classes={{
                    primary: classes.itemPrimary,
                    textDense: classes.textDense
                  }}
                >
                  {text}
                </ListItemText>
              </ListItem>
            ))}
            <Divider className={classes.divider} />
          </React.Fragment>
        ))}
      </List>
    </Drawer>
  );
}

Navigator.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withRouter(withStyles(styles)(Navigator));
