import React, { useEffect, useState } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import {
  MuiThemeProvider,
  createMuiTheme,
  withStyles
} from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Hidden from "@material-ui/core/Hidden";
import firebase from "../../firebase";
import Navigator from "./Navigator";
import Content from "./Content";
import Subscribe from "./Subscribe";
import Header from "./Header";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";

let theme = createMuiTheme({
  typography: {
    useNextVariants: true,
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5
    }
  },
  palette: {
    primary: {
      light: "#f05545",
      main: "#b71c1c",
      dark: "#7f0000"
    }
  },
  shape: {
    borderRadius: 8
  }
});

theme = {
  ...theme,
  overrides: {
    MuiDrawer: {
      paper: {
        backgroundColor: "#2b1818"
      }
    },
    MuiButton: {
      label: {
        textTransform: "initial"
      },
      contained: {
        boxShadow: "none",
        "&:active": {
          boxShadow: "none"
        }
      }
    },
    MuiTabs: {
      root: {
        marginLeft: theme.spacing.unit
      },
      indicator: {
        height: 3,
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
        backgroundColor: theme.palette.common.white
      }
    },
    MuiTab: {
      root: {
        textTransform: "initial",
        margin: "0 16px",
        minWidth: 0,
        [theme.breakpoints.up("md")]: {
          minWidth: 0
        },
        [theme.breakpoints.down("xs")]: {
          margin: "0 8px"
        }
      },
      labelContainer: {
        padding: 0,
        display: "initial",
        [theme.breakpoints.up("md")]: {
          padding: 0
        },
        [theme.breakpoints.down("xs")]: {
          display: "none"
        }
      }
    },
    MuiIconButton: {
      root: {
        padding: theme.spacing.unit
      }
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: 4
      }
    },
    MuiDivider: {
      root: {
        backgroundColor: "#544040"
      }
    },
    MuiListItemText: {
      primary: {
        fontWeight: theme.typography.fontWeightMedium
      }
    },
    MuiListItemIcon: {
      root: {
        color: "inherit",
        marginRight: 0,
        "& svg": {
          fontSize: 20
        }
      }
    },
    MuiAvatar: {
      root: {
        width: 32,
        height: 32
      }
    }
  },
  props: {
    MuiTab: {
      disableRipple: false
    }
  },
  mixins: {
    ...theme.mixins,
    toolbar: {
      minHeight: 48
    }
  }
};

const drawerWidth = 256;

const styles = {
  root: {
    display: "flex",
    minHeight: "100vh"
  },
  drawer: {
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  appContent: {
    flex: 1,
    display: "flex",
    flexDirection: "column"
  },
  mainContent: {
    flex: 1,
    background: "#000"
  }
};

const Dashboard = ({ classes }) => {
  const [mobileOpen, setMobileOpen] = useState(true);
  const [helpDialogOpen, setHelpDialogOpen] = useState(false);
  const [activeSubscription, setActiveSubscription] = useState(false);
  const [selectedChannel, setSelectedChannel] = useState();

  const handleDrawerToggle = () => setMobileOpen(!mobileOpen);
  const handleHelpDialogOpen = () => setHelpDialogOpen(true);
  const handleHelpDialogClose = () => setHelpDialogOpen(false);

  function checkActiveSubscription(uid) {
    const bodyFormData = new FormData();
    bodyFormData.set("uid", uid);
    axios({
      method: "post",
      url: "https://api.dominitec.com/find_active_subscription.php",
      data: bodyFormData,
      config: { headers: { "Content-Type": "multipart/form-data" } }
    })
      .then(function(response) {
        //handle success
        if (response.data == 1) {
          setActiveSubscription(true);
        } else {
          setActiveSubscription(false);
        }
      })
      .catch(function(response) {
        //handle error
        console.log(response);
      });
  }

  function addNewSubscription(uid, days, orderId, subscriptionId) {
    const bodyFormData = new FormData();
    bodyFormData.set("uid", uid);
    bodyFormData.set("days", days);
    bodyFormData.set("order_id", orderId);
    bodyFormData.set("subscription_id", subscriptionId);
    axios({
      method: "post",
      url: "https://api.dominitec.com/gallerismo/insert_subscription.php",
      data: bodyFormData,
      config: { headers: { "Content-Type": "multipart/form-data" } }
    }).catch(function(response) {
      //handle error
      console.error(response);
    });
  }

  function handlePayPalSuccess(details) {
    const user = firebase.auth().currentUser;
    const daysOfSubscription = 31; // hardcoded to 31 days
    const orderId = details && details.orderID;
    const subscriptionId = details && details.subscriptionID;

    addNewSubscription(user.uid, daysOfSubscription, orderId, subscriptionId);
    setActiveSubscription(true);
  }

  useEffect(() => {
    const currentUser = firebase.auth().currentUser;

    if (currentUser) {
      checkActiveSubscription(currentUser.uid);
    }

    const listener = firebase.auth().onAuthStateChanged(user => {
      if (user) {
        checkActiveSubscription(user.uid);
      }
    });

    return () => listener();
  });

  return (
    <MuiThemeProvider theme={theme}>
      <div className={classes.root}>
        <CssBaseline />
        <nav className={classes.drawer}>
          <Hidden mdUp implementation="js">
            <Navigator
              PaperProps={{ style: { width: drawerWidth } }}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              onChannelSelect={channel => {
                setSelectedChannel(channel);
                setMobileOpen(false);
              }}
            />
          </Hidden>
          <Hidden smDown implementation="css">
            <Navigator
              PaperProps={{ style: { width: drawerWidth } }}
              onChannelSelect={channel => {
                setSelectedChannel(channel);
                setMobileOpen(false);
              }}
            />
          </Hidden>
        </nav>
        <div className={classes.appContent}>
          <Header
            onDrawerToggle={handleDrawerToggle}
            onHelpDialogOpen={handleHelpDialogOpen}
            channel={selectedChannel}
          />
          <main className={classes.mainContent}>
            {activeSubscription || true ? (
              <Content channel={selectedChannel} />
            ) : (
              <Subscribe onPayPalSuccess={handlePayPalSuccess} />
            )}
          </main>
          <Dialog open={helpDialogOpen} onClose={handleHelpDialogClose}>
            <DialogTitle>Ayuda</DialogTitle>
            <p style={{ padding: "0 25px", fontSize: "1rem" }}>
              Si necesita ayuda o información, contáctenos via WhatsApp al{" "}
              <a href="https://wa.me/18292645257">829-264-5257</a>
            </p>
          </Dialog>
        </div>
      </div>
    </MuiThemeProvider>
  );
};

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Dashboard);
