import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import Hidden from "@material-ui/core/Hidden";

// @material-ui/icons

// core components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Parallax from "components/Parallax/Parallax.jsx";

import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx";
import siteLogo from "assets/img/logo.png";

// Sections for this page
import ProductSection from "./Sections/ProductSection.jsx";

const dashboardRoutes = [];

class LandingPage extends React.Component {
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
        <Header
          color="white"
          routes={dashboardRoutes}
          brand="Gallerismo"
          rightLinks={<HeaderLinks />}
          fixed
          {...rest}
        />
        <Parallax filter image={require("assets/img/landing-bg.jpg")}>
          <div className={classes.container}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <h1 className={classes.title}>Combates En Vivo</h1>
                <h4>
                  GALLERISMO es una plataforma donde puedes disfrutar de peleas
                  de gallos en vivo desde diversos clubes gallísticos y coliseos
                  de República Dominicana. Mantente al tanto del mundo del
                  gallerismo viendo las carteleras, resultados, estadísticas,
                  grabaciones y más al alcance de tus manos.
                </h4>
                <br />
                <Button color="primary" size="lg" href="/login">
                  EMPIEZA AHORA
                </Button>
              </GridItem>
              <GridItem
                className={classes.logoContainer}
                xs={12}
                sm={12}
                md={6}
              >
                <Hidden smDown>
                  <img
                    alt="Gallerismo"
                    style={{ width: "75%" }}
                    src={siteLogo}
                  />
                </Hidden>
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.container}>
            <ProductSection />
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withStyles(landingPageStyle)(LandingPage);
