import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import fingerprint from "fingerprintjs2";
import axios from "axios";

import firebase from "../../firebase";
import VideoPlayer from "../../components/VideoPlayer/VideoPlayer";
import Welcome from "../Dashboard/Welcome";
import AlreadyViewing from "../Dashboard/AlreadyViewing";

const styles = theme => ({
  paper: {
    maxWidth: 936,
    margin: "auto",
    overflow: "hidden"
  },
  searchBar: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)"
  },
  searchInput: {
    fontSize: theme.typography.fontSize
  },
  block: {
    display: "block"
  },
  addUser: {
    marginRight: theme.spacing.unit
  },
  contentWrapper: {
    margin: "40px 16px"
  }
});

function Content({ channel }) {
  const [isUnique, setIsUnique] = useState(true);
  const [uid, setUid] = useState(
    firebase.auth().currentUser && firebase.auth().currentUser.uid
  );
  const videoJsOptions = {
    autoplay: true,
    controls: true,
    sources: [
      {
        src: `https://dominitec.ddns.net/hls/desktop/${channel}.m3u8`,
        type: "application/x-mpegURL"
      }
    ]
  };

  function getBrowserFingerprint() {
    return fingerprint.getPromise().then(function(components) {
      const values = components.map(function(component) {
        return component.value;
      });

      return fingerprint.x64hash128(values.join(""), 31);
    });
  }

  function updateLastActive(uid, browser_fingerprint, stream_id) {
    const bodyFormData = new FormData();
    bodyFormData.set("uid", uid);
    bodyFormData.set("browser_fingerprint", browser_fingerprint);
    bodyFormData.set("stream_id", stream_id);

    axios({
      method: "post",
      url: "https://api.dominitec.com/update_last_active.php",
      data: bodyFormData,
      config: { headers: { "Content-Type": "multipart/form-data" } }
    }).catch(function(response) {
      console.log(response);
    });
  }

  function checkUniqueViewer() {
    const bodyFormData = new FormData();
    bodyFormData.set("uid", uid);

    axios({
      method: "post",
      url: "https://api.dominitec.com/check_is_active.php",
      data: bodyFormData,
      config: { headers: { "Content-Type": "multipart/form-data" } }
    })
      .then(function(response) {
        const {
          last_active_timestamp,
          browser_fingerprint,
          current_timestamp
        } = response.data || {};
        const is_active = last_active_timestamp
          ? current_timestamp - last_active_timestamp < 90
          : false;

        getBrowserFingerprint().then(fingerprint => {
          if (is_active && fingerprint !== browser_fingerprint) {
            setIsUnique(false);
          } else {
            updateLastActive(uid, fingerprint, channel);
            setIsUnique(true);
          }
        });
      })
      .catch(function(response) {
        console.log(response);
      });
  }

  // run once at first render
  useEffect(() => {
    let firebaseListener = () => {};
    let timer;

    if (!uid) {
      const currentUser = firebase.auth().currentUser;

      if (currentUser) {
        setUid(currentUser.uid);
      }

      firebaseListener = firebase.auth().onAuthStateChanged(user => {
        if (user) {
          setUid(user.uid);
        }
      });
    }

    if (channel) {
      if (window.requestIdleCallback) {
        requestIdleCallback(checkUniqueViewer);
      } else {
        setTimeout(checkUniqueViewer, 500);
      }

      // update/check unique viewer status every 60 seconds
      timer = setInterval(checkUniqueViewer, 60000);
    }

    return () => {
      firebaseListener();
      if (timer) clearInterval(timer);
    };
  }, [uid, channel]);

  return channel ? (
    isUnique ? (
      <VideoPlayer key={channel} {...videoJsOptions} />
    ) : (
      <AlreadyViewing />
    )
  ) : (
    <Welcome />
  );
}

export default withStyles(styles)(Content);
