import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Redirect, Router, Route, Switch } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import firebase from "./firebase";

import LoginPage from "views/LoginPage/LoginPage.jsx";
import Dashboard from "views/Dashboard/Dashboard.jsx";
import LandingPage from "views/LandingPage/LandingPage.jsx";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const [user, setUser] = useState();

  useEffect(() => {
    const listener = firebase.auth().onAuthStateChanged(setUser);

    return () => listener();
  });

  return (
    <Route
      {...rest}
      render={props => {
        if (typeof user === "undefined") return null;
        return user !== null || true ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        );
      }}
    />
  );
};

const history = createBrowserHistory();

ReactDOM.render(
  <>
    <CssBaseline />
    <Router history={history}>
      <Switch>
        <Route path="/login" component={LoginPage} />
        <PrivateRoute path="/panel" component={Dashboard} />
        <Route path="/" component={Dashboard} />
      </Switch>
    </Router>
  </>,
  document.getElementById("root")
);
