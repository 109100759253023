import React from "react";
import { withStyles } from "@material-ui/core/styles";

import { PayPalButton } from "../../components/Paypal/PayPalButton";

const styles = theme => ({
  paper: {
    maxWidth: 936,
    margin: "auto",
    overflow: "hidden"
  },
  searchBar: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)"
  },
  searchInput: {
    fontSize: theme.typography.fontSize
  },
  block: {
    display: "block"
  },
  addUser: {
    marginRight: theme.spacing.unit
  },
  contentWrapper: {
    margin: "40px 16px"
  }
});

function onError() {
  console.log("Paypal onError");
}

function onCancel() {
  console.log("Paypal onCancel");
}

function Content({ onPayPalSuccess }) {
  return (
    <div style={{ margin: "20px", padding: "20px", backgroundColor: "white" }}>
      <h2>¡Bienvenido!</h2>
      <p style={{ fontSize: "1rem" }}>
        Estamos trabajando para agregar nuevos clubes gallísticos. Por ahora
        puedes disfrutar de las transmisiones en vivo en los siguientes días:
      </p>
      <ul style={{ fontSize: "1rem" }}>
        <li>
          <strong>Domingos</strong>: Club Gallístico Arroyo Hondo
        </li>
        <li>
          <strong>Martes</strong>: Club Gallístico Laguna Prieta
        </li>
      </ul>
      <p style={{ fontSize: "1rem" }}>
        Debes tener una suscripción activa para disfrutar de las transmisiones
        en vivo. Puedes realizar el pago utilizando PayPal o directamente con
        tarjetas de crédito/débito haciendo click en los siguientes botones:
      </p>
      <PayPalButton
        createSubscription={(data, actions) =>
          actions.subscription.create({ plan_id: "P-9F454307WA0186432LWJZUNI" })
        }
        options={{
          clientId:
            "ARXdmid4kDrrHICgQoLd55E1J5KLKCej_Vz8uEyspAfG5qQW4wwd4JWsB3zrBx-N8OGBaqcl9dGLIutk",
          vault: true
        }}
        onApprove={onPayPalSuccess}
        onError={onError}
        onCancel={onCancel}
      />
      <h3>
        <strong>Precio: $9.99 por mes (+ procesamiento)</strong>
      </h3>
      <p style={{ fontSize: "1rem" }}>
        Si necesita ayuda o desea utilizar otro método de pago, contáctenos por
        <strong>
          {" "}
          WhatsApp al <a href="https://wa.me/18292645257">829-264-5257</a>
        </strong>
        .
      </p>
    </div>
  );
}

export default withStyles(styles)(Content);
