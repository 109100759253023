import React, { useState } from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Grid from "@material-ui/core/Grid";
import HelpIcon from "@material-ui/icons/Help";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import ListAltIcon from "@material-ui/icons/ListAlt";
import VideoLibrary from "@material-ui/icons/VideoLibrary";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CalendarIcon from "@material-ui/icons/CalendarToday";
import VideoCamIcon from "@material-ui/icons/Videocam";

const lightColor = "rgba(255, 255, 255, 0.7)";

const styles = theme => ({
  secondaryBar: {
    zIndex: 0
  },
  menuButton: {
    marginLeft: -theme.spacing.unit
  },
  iconButtonAvatar: {
    padding: 4
  },
  link: {
    textDecoration: "none",
    color: lightColor,
    "&:hover": {
      color: theme.palette.common.white
    }
  },
  button: {
    borderColor: lightColor
  }
});

function Header(props) {
  const { classes, channel, onDrawerToggle, onHelpDialogOpen } = props;
  const [tabValue, setTabValue] = useState("transmision");

  const onTabChange = (event, value) => {
    setTabValue(value);
  };

  function getChannelName(channelId) {
    switch (channelId) {
      case "lomadecabrera":
        return "Loma de Cabrera";

      case "mao":
        return "Coliseo de Mao";

      case "lagunaprieta":
        return "Laguna Prieta";

      case "losbombones":
        return "Los Bombones";

      case "corozito":
        return "Corozito";

      case "hermanospena":
        return "Hermanos Peña";

      default:
        return "Inicio";
    }
  }

  return (
    <React.Fragment>
      <AppBar
        component="div"
        className={classes.secondaryBar}
        color="primary"
        position="static"
        elevation={0}
      >
        <Toolbar>
          <Grid container alignItems="center" spacing={8}>
            <Hidden mdUp>
              <Grid item>
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={onDrawerToggle}
                  className={classes.menuButton}
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Hidden>
            <Grid item xs>
              <Typography color="inherit" variant="h5">
                {getChannelName(channel)}
              </Typography>
            </Grid>
            <Grid item>
              <Tooltip title="Ayuda">
                <IconButton onClick={onHelpDialogOpen} color="inherit">
                  <HelpIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      {channel ? (
        <AppBar
          component="div"
          className={classes.secondaryBar}
          color="primary"
          position="static"
          elevation={0}
        >
          <Tabs value={tabValue} onChange={onTabChange} variant="fullWidth">
            <Tab
              value="transmision"
              label="Transmisión"
              icon={<VideoCamIcon />}
            />
            <Tab
              value="grabaciones"
              label="Grabaciones"
              icon={<VideoLibrary />}
              disabled
            />
            <Tab
              value="resultados"
              label="Resultados"
              icon={<CheckCircleOutlineIcon />}
              disabled
            />
            <Tab
              value="carteleras"
              label="Carteleras"
              icon={<ListAltIcon />}
              disabled
            />
            <Tab
              value="eventos"
              label="Eventos"
              icon={<CalendarIcon />}
              disabled
            />
          </Tabs>
        </AppBar>
      ) : null}
    </React.Fragment>
  );
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  channel: PropTypes.string,
  onDrawerToggle: PropTypes.func.isRequired,
  onHelpDialogOpen: PropTypes.func.isRequired
};

export default withStyles(styles)(Header);
