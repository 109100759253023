import React, { useState, useEffect } from "react";
import { Redirect, Link } from "react-router-dom";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import People from "@material-ui/icons/People";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";

import loginPageStyle from "assets/jss/material-kit-react/views/loginPage.jsx";
import firebase from "../../firebase";

import logo from "assets/img/logo.png";
import FirebaseAuth from "./FirebaseAuth";

const LoginPage = props => {
  const { classes } = props;
  const [cardAnimaton, setCardAnimation] = useState("cardHidden");
  const [user, setUser] = useState();

  useEffect(() => {
    if (cardAnimaton) {
      setTimeout(() => setCardAnimation(""), 700);
    }

    const listener = firebase.auth().onAuthStateChanged(setUser);

    return () => listener();
  });

  return user || true ? (
    <Redirect to="/panel" />
  ) : (
    <div
      className={classes.pageHeader}
      style={{
        background:
          "linear-gradient(180deg, rgba(63,63,63,1) 0%, rgba(0,0,0,1) 100%)"
      }}
    >
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={6} md={4}>
            <Card className={classes[cardAnimaton]}>
              <form className={classes.form}>
                <CardHeader color="primary" className={classes.cardHeader}>
                  <img
                    style={{ width: "65%" }}
                    alt="Logo de Gallerismo"
                    src={logo}
                  />
                </CardHeader>
                <CardBody style={{ paddingLeft: 0, paddingRight: 0 }}>
                  {/*
                    <CustomInput
                      labelText="Usuario ó teléfono móvil"
                      id="first"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "text",
                        endAdornment: (
                          <InputAdornment position="end">
                            <People className={classes.inputIconsColor} />
                          </InputAdornment>
                        )
                      }}
                    />
                    <CustomInput
                      labelText="Contraseña"
                      id="pass"
                      formControlProps={{
                        fullWidth: true
                      }}
                      labelProps={{ style: { top: "7px" } }}
                      inputProps={{
                        type: "password",
                        endAdornment: (
                          <InputAdornment position="end">
                            <Icon className={classes.inputIconsColor}>
                              lock_outline
                            </Icon>
                          </InputAdornment>
                        )
                      }}
                    />
                    */}
                  <FirebaseAuth />
                </CardBody>
                <CardFooter className={classes.cardFooter}>
                  {/*
                    <Link
                      style={{
                        textDecoration: "none",
                        color: "inherit"
                      }}
                      to="/panel"
                    >
                      <Button color="primary" size="lg">
                        ENTRAR
                      </Button>
                    </Link>
                    <Button color="warning" size="lg">
                      REGISTRARSE
                    </Button>
                    */}
                </CardFooter>
              </form>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
};

export default withStyles(loginPageStyle)(LoginPage);
