import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import Chat from "@material-ui/icons/Chat";
import CheckCircle from "@material-ui/icons/CheckCircle";
import VideoLibrary from "@material-ui/icons/VideoLibrary";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import InfoArea from "components/InfoArea/InfoArea.jsx";

import productStyle from "assets/jss/material-kit-react/views/landingPageSections/productStyle.jsx";

class ProductSection extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={8}>
            <h2 className={classes.title}>Nuestra plataforma</h2>
            <h5 className={classes.description}>
              Transmitimos en HD y en tiempo real para que puedas disfrutar de
              los combates en cualquier dispositivo y desde cualquier lugar. Al
              adquirir una suscripción a la plataforma obtienes acceso inmediato
              a todos los clubes gallísticos y coliseos disponibles hasta el
              momento, que además de la transmisión de sus peleas en vivo podrás
              disfrutar de:
            </h5>
          </GridItem>
        </GridContainer>
        <div>
          <GridContainer>
            <GridItem xs={12} sm={12} md={4}>
              <InfoArea
                title="Sala de chat"
                description="Chatea en vivo con los demás galleros que están viendo la transmisión para que puedas expresarte y compartir opiniones."
                icon={Chat}
                iconColor="info"
                vertical
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <InfoArea
                title="Resultados inmediatos"
                description="Puedes ver la cartelera de la jugada completa y los resultados de los combates a medida que se van completando."
                icon={CheckCircle}
                iconColor="success"
                vertical
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <InfoArea
                title="Grabaciones"
                description="Todas las peleas quedan grabadas y disponibles para su descarga, por lo que podrás disfrutarlas en cualquier momento."
                icon={VideoLibrary}
                iconColor="danger"
                vertical
              />
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}

export default withStyles(productStyle)(ProductSection);
