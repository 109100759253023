import { title } from "assets/jss/material-kit-react.jsx";

const productStyle = {
  section: {
    padding: "0 0 35px",
    textAlign: "center"
  },
  "@media (min-width: 768px)": {
    section: {
      padding: "70px 0"
    }
  },
  title: {
    ...title,
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none"
  },
  description: {
    color: "#000"
  }
};

export default productStyle;
